import { ADD_PERMISSION, PROMOTIONAL_PLANNING_PROMO_EVENT_LIST } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "numeral";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "ph-search-by": "Search by Key Account, Event", "search-by": "KeyAccount,Name", "title": "Event List" }, scopedSlots: _vm._u([{ key: "create-buttons", fn: function(ref) {
    var onClick = ref.onClick;
    return [_c("div", [_vm.can(_vm.permissions.add) ? _c("a-button", { attrs: { "type": "primary" }, on: { "click": onClick } }, [_vm._v("Add Event List")]) : _vm._e()], 1)];
  } }]) }, [_c("url-field", { key: "KeyAccount", attrs: { "base-url": "/promotion-planning/event-list", "data-index": "keyAccount", "title": "Key Account", "sorter": true } }), _c("text-field", { key: "Name", attrs: { "data-index": "name", "title": "Event", "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListEvent",
  inject: ["can"],
  data() {
    return {
      permissions: {
        add: ADD_PERMISSION
      }
    };
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListEvent = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.menuItems } }), _c("resource", { attrs: { "name": "promotion-planning.events", "api-url": _vm.apiUrl, "create-route": "/promotion-planning/event-list/create", "edit-route": "/promotion-planning/event-list/:id", "page": _vm.page } }, [_c("list-event")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { ListEvent },
  data() {
    const menuItems = [
      {
        title: "Master Files",
        path: ""
      },
      {
        title: "Event List",
        path: "/promotion-planning/event-list"
      }
    ];
    return {
      apiUrl,
      menuItems,
      page: PROMOTIONAL_PLANNING_PROMO_EVENT_LIST
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
